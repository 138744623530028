/** @jsx jsx */
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import { Fragment } from "react"
import { Flex, jsx } from "theme-ui"

import Border from "@components/Border"
import Hero from "@components/Hero"
import TrustBox from "@components/TrustBox"
import Variant from "@components/Variant"

export const fragment = graphql`
  fragment ContentfulHeroBlock on ContentfulHeroBlock {
    body {
      body
    }
    roundedImage
    ctaLinks {
      href
      id
      slug
      title
    }
    heading {
      heading
    }
    id
    media {
      ...Media
    }
    node_locale
    simpleList: list {
      id
      items {
        id
        text
      }
    }
    trustpilotConfiguration {
      businessunitId
      tags
      reviewLanguages
      microStarsTemplateId
      targetUrl
    }
  }
`

export default function HeroBlock({
  body,
  ctaLinks,
  heading,
  media,
  node_locale,
  simpleList,
  roundedImage,
  trustpilotConfiguration: {
    businessunitId,
    microStarsTemplateId,
    targetUrl,
    reviewLanguages,
    tags,
  },
}) {
  return (
    <Fragment>
      <Variant
        as="section"
        d="flex"
        sx={{
          alignItems: "center",
          flexDirection: "column",
          py: [15, 18],
        }}
        variant="container"
      >
        <Hero
          ctaLinks={ctaLinks}
          list={simpleList?.items}
          media={media}
          text={body?.body}
          title={heading?.heading}
          roundedImage={roundedImage && roundedImage}
        >
          <Flex
            sx={{
              justifyContent: ["center", "flex-start"],
              maxWidth: [null, "270px"],
              minHeight: "20px",
            }}
          >
            <TrustBox
              businessunitId={businessunitId}
              height="20px"
              locale={node_locale}
              reviewLanguages={reviewLanguages}
              tags={tags}
              targetUrl={targetUrl}
              templateId={microStarsTemplateId}
            />
          </Flex>
        </Hero>
      </Variant>
      <Border />
    </Fragment>
  )
}

HeroBlock.typeName = "ContentfulHeroBlock"

HeroBlock.defaultProps = {
  body: null,
  ctaLinks: null,
  simpleList: null,
}

HeroBlock.propTypes = {
  body: PropTypes.shape({
    body: PropTypes.string.isRequired,
  }),
  ctaLinks: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string,
      slug: PropTypes.string,
      title: PropTypes.string.isRequired,
    })
  ),
  heading: PropTypes.shape({
    heading: PropTypes.string.isRequired,
  }).isRequired,
  media: PropTypes.object.isRequired,
  node_locale: PropTypes.string.isRequired,
  simpleList: PropTypes.shape({
    title: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
      }).isRequired
    ).isRequired,
  }),
  trustpilotConfiguration: PropTypes.shape({
    businessunitId: PropTypes.string.isRequired,
    reviewLanguages: PropTypes.string.isRequired,
    tags: PropTypes.string.isRequired,
    targetUrl: PropTypes.string.isRequired,
    microStarsTemplateId: PropTypes.string.isRequired,
  }).isRequired,
}
